import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class ServiceLE1Page extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="LE1"
          description="View route information and buy your ticket for the LE1 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">LE1 service</h1>
              <p>Shaftesbury Junction – Leeds East Academy</p>
              <p>Leeds East Academy – Shaftesbury Junction</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="LE1 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1CQ8zChhB_mX33Vnrl__94wXQBHP-948&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Leeds East Academy
                </p>
                <p>
                  From Shaftesbury Junction (stop D – 45010734), Harehills Lane, Compton Road, Stanley Road, Harehills Road, Roundhay Road, Harehills Lane, Foundry Approach, Coldcotes Circus, Coldcotes Drive, Oakwood Lane, Wykebeck Valley Road, Foundry Lane, South Parkway Approach, South Parkway to Leeds East Academy.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="LE1 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1mOCM-teMju0PODl4UfM1ck6lyyCtEYw&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Leeds East Academy
                </p>
                <p>
                  From Leeds East Academy, South Parkway, South Parkway Approach, Foundry Lane, Wykebeck Valley Road, Oakwood Lane, Coldcotes Drive, Coldcotes Circus, Foundry Approach, Harehills lane, Roundhay Road, Harehills Road, Stanley Road, Compton Road, Harehills Lane, Shaftesbury Junction.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Shaftesbury Junction</td>
                      <td>DEP</td>
                      <td>0755</td>
                    </tr>
                    <tr>
                      <td>Harehills Road/Compton Road</td>
                      <td>DEP</td>
                      <td>0758</td>
                    </tr>
                    <tr>
                      <td>Foundry Approach/Foundry Place</td>
                      <td>DEP</td>
                      <td>0810</td>
                    </tr>
                    <tr>
                      <td>Leeds East Academy</td>
                      <td>ARR</td>
                      <td>0820</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="2">Afternoon</th>
                      <th style={{ fontSize: '0.8em' }}>Mon&nbsp;&amp;&nbsp;Fri</th>
                      <th style={{ fontSize: '0.8em' }}>Tue/Wed/Thu</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Leeds East Academy</td>
                      <td>DEP</td>
                      <td>1450</td>
                      <td>1540</td>
                    </tr>
                    <tr>
                      <td>Foundry Approach/Foundry Place</td>
                      <td>DEP</td>
                      <td>1455</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Harehills Road/Compton Road</td>
                      <td>DEP</td>
                      <td>1505</td>
                      <td>1555</td>
                    </tr>
                    <tr>
                      <td>Shaftesbury Junction</td>
                      <td>ARR</td>
                      <td>1508</td>
                      <td>1558</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>22/23 fares</h2>
                <p>There is only one payment option for this service:</p>
                <ul>
                  <li><strong>Single one way fare -  £1.</strong></li>
                  <li>Drivers accept cash and contactless payments.</li>
                  <li><strong>PLEASE NOTE:</strong> Metro pre paid passes will not be accepted on this service from September 2022.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default ServiceLE1Page
